import React, {createContext, useContext} from 'react';
import NetworkingAPI from "../helpers/NetworkingAPI";
import {useAuth} from "./useAuth";


const LoggerContext = createContext({
    logEvent: async (guest) => Promise,
});

export const LoggerProvider = ({children}) => {

    const { getUser } = useAuth();

    const logEvent = async (event) => {
        try {
            const currentUser = await getUser()
            if (!currentUser) {
                return undefined;
            }
            const payload = {
                event: event?.event,
                target: event?.target,
                timestamp: new Date(new Date().getTime() + (-5 * 60) * 60 * 1000) + "",//new Date().toDateString(),
                userId: currentUser?._id
            }

            const dataResponse = await NetworkingAPI.fetchDataFullResponse('analytics/event/create', 'POST', undefined, payload)
            const result = dataResponse?.response?.data;
            if (result) {
                console.log('Event Logged')
            }
        } catch (error) {
            console.log(`Error: ${error}`)
        }
    }

    return (
        <LoggerContext.Provider value={{ logEvent }}>
            {children}
        </LoggerContext.Provider>
    );
};

// Create a custom hook to use the AuthContext
export const useLogger = () => {
    return useContext(LoggerContext);
};
