import React, {createContext, useContext, useEffect} from 'react';
import NetworkingAPI from "../helpers/NetworkingAPI";
import {useLogger} from "./useLogger";
import {APIBaseURL, Subdomain} from "../util/DomainHelper";

const CompanyContext = createContext({
    getCompany: () => {},
    getCompanyConfig: () => {},
    setCompanyConfig: (data) => {},
    setCompany: (data) => {},
});

export const CompanyProvider = ({children}) => {

    const { logEvent } = useLogger();

    const setCompany = (data) => {
        if (data) {
            localStorage.setItem('company', JSON.stringify(data));
            return true
        } else {
            return undefined;
        }
    };

    const setCompanyConfig = (data) => {
        if (data) {
            localStorage.setItem('company_config', data);
            return true
        } else {
            return undefined;
        }
    };

    const getCompany = () => {
        const foundCompany = localStorage.getItem('company');
        if (foundCompany) {
            return JSON.parse(foundCompany);
        } else {
            return null
        }
    };

    const getCompanyConfig = () => {
        const foundConfig = localStorage.getItem('company_config');
        if (foundConfig) {
            return JSON.parse(foundConfig);
        } else {
            return null
        }
    };

    const refetchCompany = async () => {
        try {
            console.log('FETCHING...')
            if (Subdomain.toLowerCase() === 'www') {
                console.log('NOT_FOUND')
                return { company: undefined, config: undefined, error: undefined }
            }

            console.log('FETCHING 2...')
            // await logEvent({event: 'app_session', target: 'opened'})
            const dataResponse = await NetworkingAPI.fetchDataFullResponse(`company/slug/${Subdomain.toLowerCase()}`, 'GET', undefined, undefined)
            const result = dataResponse?.response?.data;
            const message = dataResponse?.response?.message;
            if (result) {
                const company = result?.company;
                const config = result?.configuration;
                if (company && config) {

                    setCompany(company);
                    setCompanyConfig(config);

                    const currentPath = window.location.pathname;
                    const origin = window.location.origin;

                    if (currentPath === '/error') {
                        window.location.href = `${origin}/`;
                    }

                    if (config.primaryColor) {
                        document.documentElement.style.setProperty('--base', config.primaryColor);
                    }
                    if (config.secondaryColor) {
                        document.documentElement.style.setProperty('--base-2', config.secondaryColor);
                    }
                    if (config.favicon) {
                        const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        document.head.removeChild(favicon);
                        favicon.rel = 'icon';
                        favicon.href = config.favicon;
                        document.head.appendChild(favicon);
                    }

                    return { company: company, config: config, error: undefined }
                }
                return { company: undefined, config: undefined, error: message ?? 'An error occurred' }
            }
            return { company: undefined, config: undefined, error: undefined }
        } catch (error) {
            console.log(`Error: ${error}`);
            return { company: undefined, config: undefined, error: 'An unexpected error occurred' }
        }
    }

    const removeCompany = () => {
        localStorage.removeItem('company');
        localStorage.removeItem('company_config');
        return true
    }

    useEffect(() => {
        refetchCompany()
    },[]);

    return (
        <CompanyContext.Provider value={{ getCompany, getCompanyConfig, refetchCompany, setCompany, setCompanyConfig }}>
            {children}
        </CompanyContext.Provider>
    );
};

// Create a custom hook to use the AuthContext
export const useCompany = () => {
    return useContext(CompanyContext);
};
