import React from "react";

const CompanyNotFoundView = () => {

    return(
        <div style={{
            minHeight: '100vh',
            minWidth: '100vw',
            backgroundColor: 'blue',
            position: 'absolute',
            top: 0,
            left: 0
        }}>
            <h1> Company Not Found</h1>
        </div>
    )

}

export default CompanyNotFoundView;
