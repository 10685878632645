import React from "react";
// import "dotenv/config.js";
import ReactDOM from "react-dom/client";
import App from "./App";
import {LoggerProvider} from "./hooks/useLogger";
import {AuthProvider} from "./hooks/useAuth";
import {CompanyProvider} from "./hooks/useTenant";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<LoggerProvider>
		<CompanyProvider>
			<AuthProvider>
				<App />
			</AuthProvider>
		</CompanyProvider>
	</LoggerProvider>
);
