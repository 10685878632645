const InitializingView = () => {

    return(
        <div style={{ height: '100%', width: '100%', backgroundColor: 'red' }}>

        </div>
    )

}

export default InitializingView;
